<template>
  <div id="app" class="w-full flex flex-col font-sans text-bodycopy">
    <NavTopBar />

    <router-view />

    <Footer />
  </div>
</template>

<script>
import NavTopBar from './components/NavTopBar.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',

  components: {
    NavTopBar,
    Footer,
  }
}
</script>
