<template>
    <main class="w-full">
        <section
            class="flex items-center justify-center w-full px-8 bg-center bg-cover h-screenMinusMobileHeader md:h-screenMinusHeader"
            style="background-image: url('/images/hero.jpg')"
        >
            <h1 class="max-w-3xl text-center text-white standard-heading">
                IT'S TIME WE CHANGE THE WAY WE FEED THE WORLD
            </h1>
        </section>

        <section class="px-8 py-24 text-center">
            <h2 class="pb-8 text-black standard-subheading">
                THE NEW FRONTIERS
            </h2>

            <div class="flex flex-col items-center">
                <p class="max-w-xl text-base">
                    At the Gordon L. Diewert Foundation, our focus is to create relationships 
                    with local farmers, businesses and non-profits organizations, so we can 
                    provide both financial aid and on-going relief to families facing food insecurity.
                </p>

                <p class="max-w-2xl pt-4 text-base">
                    This amalgamation aims to create a regenerative support system that will reduce 
                    food waste, eliminate duplicate distribution systems and create sustainable 
                    environments that benefit families facing hunger  with participation from our 
                    local businesses.
                </p>
            </div>
        </section>

        <section
            class="px-8 py-24 text-center text-white bg-center bg-cover"
            style="background-image: url('/images/forest.jpg')"
        >
            <h2 class="pb-8 text-white standard-subheading">
                A SUSTAINABLE SYSTEM
            </h2>

            <div class="flex justify-center">
                <p class="max-w-xl font-bold">
                    We are reducing the carbon footprint by eliminating duplication in transportation, 
                    storage, logistics, handling costs, distribution sites and by decreasing food 
                    waste due primarily to reduced handling.
                </p>
            </div>


            <div class="grid max-w-5xl grid-cols-1 gap-8 pt-16 m-auto md:grid-cols-6 md:gap-0">
                <a href="https://www.mfood.ca" class="flex flex-col items-center text-white hover:text-gray-300">
                    <svg xmlns="http://www.w3.org/2000/svg" id="Component_2_1" width="67.342" height="55.492" viewBox="0 0 67.342 55.492" class="w-16 h-16 fill-current">
                        <defs>
                            <!-- <style>
                                .cls-1{fill:#fff}
                            </style> -->
                        </defs>
                        <path id="Path_18" d="M109.635 75.286l-3.274-10.941a2.441 2.441 0 0 0-2.336-1.737H48.1a2.441 2.441 0 0 0-2.336 1.737l-3.271 10.941a2.275 2.275 0 0 0-.009.248 2.352 2.352 0 0 0-.091.452v36.837a5.281 5.281 0 0 0 5.272 5.277h56.8a5.281 5.281 0 0 0 5.272-5.277V75.986a2.324 2.324 0 0 0-.094-.465c-.017-.079.015-.158-.008-.235zm-4.825.885a5.641 5.641 0 0 1-10.817 1.747c0-.03.007-.057 0-.086l-1.81-10.352h10.029zm-39.7-8.691h8.519v10.233A5.587 5.587 0 0 1 63.262 78zm13.391 0h8.741l1.788 10.212a5.619 5.619 0 0 1-10.529.026zm-28.582 0h10.244l-1.74 9.92c-.01.029-.042.042-.052.071a5.682 5.682 0 0 1-11.053-1.3zm9.863 45.747V96.464h10.23v16.763zm44.684 0H74.882v-19.2a2.435 2.435 0 0 0-2.436-2.436h-15.1a2.435 2.435 0 0 0-2.436 2.436v19.2h-7.245a.4.4 0 0 1-.4-.4V84.543a10.521 10.521 0 0 0 13.413-1.658c3.92 4.182 11.481 4.182 15.383 0a11 11 0 0 0 15.387 0 10.514 10.514 0 0 0 13.414 1.654v28.284a.4.4 0 0 1-.399.404z" class="cls-1" transform="translate(-42.393 -62.608)"/>
                        <path id="Path_19" d="M95.384 91.592H82.6a2.435 2.435 0 0 0-2.436 2.436v12.781a2.435 2.435 0 0 0 2.436 2.436h12.78a2.435 2.435 0 0 0 2.437-2.436V94.028a2.435 2.435 0 0 0-2.433-2.436zm-2.436 12.78H85.04v-7.908h7.908z" class="cls-1" transform="translate(-42.393 -62.608)"/>
                    </svg>

                    <span class="pt-4 text-sm">Grocery Store</span>
                </a>

                <div class="flex flex-col items-center">
                    <img class="w-16 h-16" :src="`${assetsUrl}/images/collect.svg`" alt="">

                    <span class="pt-4 text-sm" style="max-width: 80px">Collect & Transport</span>
                </div>

                <div class="flex flex-col items-center">
                    <img class="w-16 h-16" :src="`${assetsUrl}/images/distribution.svg`" alt="">

                    <span class="pt-4 text-sm" style="max-width: 86px">Distribution Site</span>
                </div>

                <div class="flex flex-col items-center">
                    <img class="w-16 h-16" :src="`${assetsUrl}/images/collect.svg`" alt="">

                    <span class="pt-4 text-sm" style="max-width: 80px">Collect & Distribute</span>
                </div>

                <a href="https://citydreamcentre.com" class="flex flex-col items-center text-white hover:text-gray-300">
                    <div class="flex items-center justify-center w-16 h-16">
                        <i class="fas fa-store-alt fa-3x"></i>
                    </div>

                    <span class="pt-4 text-sm" style="max-width: 96px">Community Organization</span>
                </a>

                <a href="https://www.mfood.ca" class="flex flex-col items-center text-white hover:text-gray-300">
                    <svg xmlns="http://www.w3.org/2000/svg" id="Component_7_1" width="88.415" height="58.88" viewBox="0 0 88.415 58.88" class="w-16 h-16 fill-current">
                        <g id="Group_8" transform="translate(-642.559 -60.603)">
                            <path id="Path_28" d="M698.858 82.558a10.977 10.977 0 1 1 10.979-10.976 10.99 10.99 0 0 1-10.979 10.976zm0-17.147a6.169 6.169 0 1 0 6.171 6.171 6.176 6.176 0 0 0-6.171-6.171z" class="cls-1"/>
                        </g>
                        <g id="Group_9" transform="translate(-642.559 -60.603)">
                            <path id="Path_29" d="M707.254 119.483h-16.792a2.4 2.4 0 0 1-2.4-2.4V95.491a10.8 10.8 0 0 1 21.6 0v21.587a2.4 2.4 0 0 1-2.408 2.405zm-14.388-4.809h11.984V95.491a5.992 5.992 0 0 0-11.984 0z" class="cls-1"/>
                        </g>
                        <g id="Group_10" transform="translate(-642.559 -60.603)">
                            <path id="Path_30" d="M674.143 82.558a10.977 10.977 0 1 1 10.979-10.976 10.988 10.988 0 0 1-10.979 10.976zm0-17.147a6.169 6.169 0 1 0 6.171 6.171 6.173 6.173 0 0 0-6.171-6.171z" class="cls-1"/>
                        </g>
                        <g id="Group_11" transform="translate(-642.559 -60.603)">
                            <path id="Path_31" d="M682.54 119.483h-16.788a2.4 2.4 0 0 1-2.405-2.4V95.491a10.8 10.8 0 0 1 21.6 0v21.587a2.4 2.4 0 0 1-2.407 2.405zm-14.384-4.809h11.979V95.491a5.99 5.99 0 1 0-11.979 0z" class="cls-1"/>
                        </g>
                        <g id="Group_12" transform="translate(-642.559 -60.603)">
                            <path id="Path_32" d="M651.748 90.26a9.19 9.19 0 1 1 9.19-9.19 9.2 9.2 0 0 1-9.19 9.19zm0-13.572a4.381 4.381 0 1 0 4.382 4.382 4.388 4.388 0 0 0-4.382-4.382z" class="cls-1"/>
                        </g>
                        <g id="Group_13" transform="translate(-642.559 -60.603)">
                            <path id="Path_33" d="M651.748 119.483H645.1a2.4 2.4 0 0 1-2.405-2.4V99.994a9.049 9.049 0 0 1 18.1 0v10.442a9.057 9.057 0 0 1-9.047 9.047zm-4.24-4.809h4.24a4.245 4.245 0 0 0 4.241-4.238V99.994a4.241 4.241 0 0 0-8.481 0z" class="cls-1"/>
                        </g>
                        <g id="Group_14" transform="translate(-642.559 -60.603)">
                            <path id="Path_34" d="M721.784 90.26a9.19 9.19 0 1 1 9.19-9.19 9.2 9.2 0 0 1-9.19 9.19zm0-13.572a4.381 4.381 0 1 0 4.381 4.382 4.389 4.389 0 0 0-4.381-4.382z" class="cls-1"/>
                        </g>
                        <g id="Group_15" transform="translate(-642.559 -60.603)">
                            <path id="Path_35" d="M728.428 119.483h-6.644a9.056 9.056 0 0 1-9.049-9.047V99.994a9.049 9.049 0 0 1 18.1 0v17.084a2.4 2.4 0 0 1-2.407 2.405zm-6.644-23.729a4.245 4.245 0 0 0-4.241 4.24v10.442a4.244 4.244 0 0 0 4.241 4.238h4.24v-14.68a4.245 4.245 0 0 0-4.24-4.24z" class="cls-1"/>
                        </g>
                    </svg>

                    <span class="pt-4 text-sm">Families</span>
                </a>
            </div>
        </section>

        <section class="flex flex-col w-full lg:pt-24">
            <div
                class="w-full bg-no-repeat bg-right lg:h-mfood bg-60% bg-none lg:bg-mfood"

            >
                <div class="flex flex-col items-center pt-24 pl-8 pr-8 lg:items-start lg:w-2/5 lg:pl-32">
                    <h2 class="text-black standard-subheading">
                        HERE IS HOW IT WORKS

                    </h2>

                    <h3 class="pt-8 pb-2 text-xl font-bold text-center lg:text-left">
                        IT'S FREE
                    </h3>

                    <p class="text-base text-center lg:text-left">
                        Our themed marketplaces will propel your organization into the digital world 
                        without having you to worry about the cost. Our solutions can be branded 
                        under your website and logos. Our digital platform is vertically integrated 
                        and offered an easy plug &amp; play implementation process.
                    </p>

                    <h3 class="pt-8 pb-2 text-xl font-bold text-center lg:text-left">
                        WHAT GOES AROUND<br> COMES AROUND
                    </h3>

                    <p class="text-base text-center lg:text-left">
                        We are eliminating the middleman and utilizing existing channels of 
                        distribution - <strong>grocery stores</strong>. Money raised and generated are being 
                        put into food coupons that are being given to the recipients who face 
                        food insecurity. This process increases revenue transactions with local 
                        businesses and most importantly  returns the dignity of choice for back 
                        to families experiencing a difficult time in their lives.
                    </p>

                    <h3 class="pt-8 pb-2 text-xl font-bold text-center lg:text-left">
                        TRANSPARENCY
                    </h3>

                    <p class="text-base text-center lg:text-left">
                        Our solutions are fully transparent! Yes that’s right, you can track 
                        it every transaction! - 80% of all money raised and generated is 
                        received by the food program recipient. The balance of the 20% keeps 
                        the marketplace operating and up-to-date.
                    </p>

                    <h2 class="pt-8 pb-4 text-black standard-subheading">
                        BE PART OF THE CHANGE
                    </h2>

                    <div class="flex">
                        <router-link class="standard-button" :to="{ name: 'Signup' }">
                            SIGN UP
                        </router-link>
                    </div>
                </div>

                <div class="w-full pt-24 lg:hidden">
                    <img :src="`${assetsUrl}/images/mfood2.jpg`" alt="">
                </div>
            </div>
        </section>

        <section class="flex flex-col items-center pt-24">
            <h2 class="pb-8 text-black standard-subheading">
                QUESTIONS?
            </h2>

            <div v-if="hasError" class="w-full max-w-xl px-8 mb-4">
                <ServerErrors :error="error" />
            </div>

            <div v-if="hasSuccess" class="w-full max-w-xl px-8 mb-4">
                <b-message type="is-info">
                    Success! Your message has been delivered.
                </b-message>
            </div>

            <Form v-slot="{ handleSubmit }" class="max-w-xl px-8">
                <div class="flex flex-row">
                    <TextInput
                        v-model="form.first_name"
                        name="first_name"
                        rules="required"
                        placeholder="First Name"
                        class=""
                    ></TextInput>

                    <TextInput
                        v-model="form.last_name"
                        name="last_name"
                        rules="required"
                        placeholder="Last Name"
                        class="ml-4"
                    ></TextInput>
                </div>

                <TextInput
                    v-model="form.business_name"
                    name="business_name"
                    rules="required"
                    placeholder="Business Name"
                    class="pt-4"
                ></TextInput>

                <TextInput
                    v-model="form.email"
                    name="email"
                    rules="required"
                    placeholder="Email"
                    class="pt-4"
                ></TextInput>

                <TextInput
                    v-model="form.phone"
                    name="phone"
                    rules="required"
                    placeholder="Phone Number"
                    class="pt-4"
                ></TextInput>

                <TextInput
                    v-model="form.message"
                    type="textarea"
                    name="message"
                    rules="required"
                    placeholder="Message"
                    maxlength="2000"
                    class="pt-4"
                    has-counter
                ></TextInput>

                <div class="flex justify-center w-full pt-4 pb-24">
                    <button class="standard-button" :disabled="isSubmitting" @click="handleSubmit(submit)">
                        SUBMIT
                    </button>
                </div>
            </Form>
        </section>

    </main>
</template>

<script>
import axios from 'axios';
import ServerErrors from '../components/ServerErrors.vue';

const INITIAL = 'is-initial';
const SUBMITTING = 'is-submitting';
const SUCCESS = 'has-success';
const ERROR = 'has-error';

export default {
    name: 'Home',

    components: {
        ServerErrors,
    },

    props: {},

    data() {
        return {
            state: INITIAL,
            assetsUrl: process.env.VUE_APP_ASSETS_URL,
            form: {
                first_name: '',
                last_name: '',
                business_name: '',
                email: '',
                phone: '',
                message: '',
            },
            error: undefined,
        };
    },

    computed: {
        isSubmitting() {
            return (this.state === SUBMITTING);
        },

        hasSuccess() {
            return (this.state === SUCCESS);
        },

        hasError() {
            return (this.state === ERROR);
        },
    },

    methods: {
        async submit() {
            try {
                this.state = SUBMITTING;

                await axios.post('/contact', {
                    ...this.form,
                    name: `${this.form.first_name} ${this.form.last_name}`,
                });

                this.state = SUCCESS;
            } catch (err) {
                this.error = err.response.data;
                this.state = ERROR;
                throw new Error(`Home# Problem submitting question form: ${err}`);
            }
        },
    },
};
</script>
