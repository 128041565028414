<template>
    <b-message
        :title="errorMessage"
        type="is-danger" aria-close-label="Close message"
        class="w-full"
    >
        <div v-for="(error, i) in errorFields" :key="`error-${i}`" class="flex flex-row px-4 pt-2">
            <div class="font-bold">
                {{ error[0] }}
            </div>

            <div class="pl-4">
                {{ error[1] }}
            </div>
        </div>
    </b-message>
</template>

<script>
export default {
    name: 'ServerErrors',

    props: {
        error: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {};
    },

    computed: {
        errorMessage() {
            return this.error.message;
        },

        errorFields() {
            return Object.keys(this.error.errors).map((field) => [field, this.error.errors[field][0]]);
        },
    },

    mounted() {
        // console.log('ServerErrors# error', this.error);
    },

    methods: {},

};
</script>
