import Vue from 'vue';
import axios from 'axios';
import App from './App.vue';
import './assets/tailwind.css';
import router from './router'
import Buefy from 'buefy'
import './assets/scss/app.scss'
import "./vee-validate";

Vue.use(Buefy, {
    defaultIconPack: 'fas',
});

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

Vue.component('Form', () => import(/* webpackChunkName: "Form" */ './components/Form.vue'));
Vue.component('Select', () => import(/* webpackChunkName: "Select" */ './components/Select.vue'));
Vue.component('TextInput', () => import(/* webpackChunkName: "TextInput" */ './components/TextInput.vue'));

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
